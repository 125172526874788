<template lang="pug">
  .fp-app(:class="{fullscreen:fullscreen,'no-aside':!aside,'iframe-full':iframeFull}")
    fp-splash-error(v-if="$store.getters.GLOBAL_ERROR",:error="$store.getters.GLOBAL_ERROR")
    fp-header(
      v-if="displayHeader"
      :service="service"
      :icon="icon"
      :image="image"
      :beta="beta"
      :beta4="beta4"
      :alpha="alpha"
      :alpha4="alpha4"
    )
    .header-information(
      v-if="headerInformation"
    )
      slot
    fp-loader(v-if="$store.getters.LOADING && !$store.getters.GLOBAL_ERROR")
    main(v-if="!$store.getters.GLOBAL_ERROR")
      fp-sidebar(
        v-if="!$store.getters.LOADING && $store.getters.SESSION && !fullscreen && !iframeFull && !$store.getters.GLOBAL_ERROR"
        overlap
        from-dataplant
        :current-service='service'
        :style-sidebar="{ top: headerInformation ? '95px' : '60px' }"
      )
      authentication(v-if="!$store.getters.LOADING && !$store.getters.VALID_SESSION")
      fp-aside(
        :dataplant-id="dataplantId"
        :aside='aside'
        v-if="aside && !$store.getters.LOADING && $store.getters.VALID_SESSION && !fullscreen && !iframeFull"
        :style="{ top: headerInformation ? '95px' : '70px' }"
      )
      .view(
        v-if="!$store.getters.LOADING && $store.getters.VALID_SESSION"
        :style="{ top: viewTopStyle }"
      )
        fp-loader(v-if="$store.getters.INTERNAL_LOADING")
        router-view(v-if="!$store.getters.INTERNAL_LOADING",:key="$i18n.locale()")
</template>

<script>
import _get from 'lodash/get'
import Config from '@/shared/Config'

export default {
  props: {
    service: { type: String, default: null },
    image: { type: String, default: null },
    icon: { type: String, default: null },
    beta: { type: Boolean, default: false },
    beta4: { type: Boolean, default: false },
    alpha: { type: Boolean, default: false },
    alpha4: { type: Boolean, default: false },
    headerInformation: { type: Boolean, default: false }
  },
  data () {
    return {
      aside: {},
      dataplantId: null,
      organizationId: null
    }
  },
  computed: {
    fullscreen () {
      return _get(this, '$route.meta.fullscreen')
    },
    iframeFull () {
      return _get(this, '$route.meta.iframeFull')
    },
    viewTopStyle () {
      if (this.fullscreen) return '0'
      if (this.headerInformation) return '95px'
      return '70px'
    },
    currentUser () {
      return this.$store.getters.KING_SESSION
    },
    displayHeader () {
      return !this.$store.getters.LOADING && this.$store.getters.SESSION && !this.fullscreen && !this.iframeFull && !this.$store.getters.GLOBAL_ERROR
    }
  },
  watch: {
    $route (newRoute, oldRoute) {
      // Hack that reloads the page if the dataplant is changed when accessing
      // the same service from another project
      // Either take dataplantId or projectId, depending on what the service is using
      const newProjectId = newRoute?.params?.dataplantId || newRoute?.params?.projectId
      const oldProjectId = oldRoute?.params?.dataplantId || oldRoute?.params?.projectId

      if (newProjectId !== oldProjectId) this.$router.go()
    }
  },
  async created () {
    let lang = _get(this.$route, 'query.lang') || window.localStorage.getItem('hq_lang') || 'en'
    try {
      lang = JSON.parse(lang)
    // eslint-disable-next-line no-empty
    } catch (err) {}

    this.$i18n.set(lang)
    window.localStorage.setItem('hq_lang', lang)

    this.aside = (await Config()).aside
    this.dataplantId = (await Config()).PROJECT_ID
    await this.$store.dispatch('APP_INIT')

    // Set local storage to know last service use by user in home orga page
    this.organizationId = (await Config()).ORGANIZATION_ID
    let recentInStorage = this.currentUser?.preferences?.home_page_preferences?.[this.organizationId]?.recent_projects_home || []

    if (this.organizationId && this.currentUser) {
      const newRecent = {
        type: this.service,
        projectId: this.dataplantId
      }

      recentInStorage = recentInStorage.filter(r => r.type !== newRecent.type || r.projectId !== newRecent.projectId)
      // If already in list -> put it in first place
      // If not in list, add in list at the beginning
      recentInStorage.unshift(newRecent)

      // if more than 10, keep 10 first
      if (recentInStorage.length > 10) recentInStorage = recentInStorage.slice(0, 10)

      this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.recent_projects_home`, recentInStorage)
    }

    const trackFrom = window.localStorage.getItem('tracking-from')
    if (trackFrom) {
      let service = ''
      if (this.service === 'dpe') service = 'DPE'
      else if (this.service === 'dm') service = 'DM'
      else if (this.service === 'lakehouse') service = 'LAKEHOUSE'
      else if (this.service === 'data-catalog') service = 'DATA-CATALOG'
      else if (this.service === 'am') service = 'AM'
      else if (this.service === 'iam') service = 'IAM'
      else if (this.service === 'ml') service = 'MLM'
      else if (this.service === 'gab') service = 'AAB'
      // Different implementation for the control-center. See App.vue in cc folder
      this.$analytics.track('Open service', {
        from: trackFrom,
        service
      })
      window.localStorage.removeItem('tracking-from')
    }

    // Different event because product need it to analyse in mixpanel
    const trackFromObj = window.localStorage.getItem('tracking-from-object')
    if (trackFromObj) {
      const details = JSON.parse(trackFromObj)
      this.$analytics.track('Switch project from sidebar', {
        organization_id: details.fromOrganizationId,
        project_id: details.fromProjectId,
        destination_organization_id: this.organizationId,
        destination_project_id: this.dataplantId
      })
      window.localStorage.removeItem('tracking-from-object')
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fp-app {
  background-color: @background-color_aside;
  &.v3 {
    background-color: #eff0ef;
  }
  height: 100%;
  width: 100%;
  .header-information {
    width: 100%;
    height: 25px;
  }
  .view {
    position: absolute;
    bottom: 0px;
    right: 20px;
    overflow: auto;
    top:93px;
    transition:0.3s all;
    background-color: #FFFFFF;
    box-shadow: 0 3px 13px 0 rgba(151,167,183,0.3);
    left: 124px;
  }
  &.no-aside .view {
    left: 20px;
  }
  .fp-aside {
    width:124px;
    left:0;
    top:93px;
    bottom:0;
    position: absolute;
  }
  .view {
    top:60px;
  }
  .fp-aside {
    top:60px;
  }
  &.fullscreen .view {
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  &.iframe-full .view {
    left:84px;
    right:83px;
    top:6px;

    @media screen and (max-width: 1200px) {
      left: 10px;
      right: 10px;
    }
  }
}
</style>
