import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import Store from '@/shared/store'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import moment from 'moment'
import { environmentModel } from './Environments'
import Vue from 'vue'
// import mockActions from './mock/actions'
// import mockActionsVersions from './mock/actionsVersions'

class Action extends APIObject {
  constructor (options) {
    // Init
    super('DPE', options)

    // Default variables
    this.__lastStart = null
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.params = typeof (this.params) === 'object' && this.params ? this.params : {}
    this.params.timeout = this.params.timeout !== undefined ? this.params.timeout : '2h'
    this.params.stop_on_failure = (typeof (this.params.stop_on_failure) === 'boolean') ? this.params.stop_on_failure : false
    this.display_name = this.display_name || ''
    this.modules = this.modules || []
    this.starting = this.starting || false
    this.language = this.language || 'python3'
    // Envs
    this.environment = this.environment || {}
    switch (typeof (this.environment)) {
      case 'string':
        break
      case 'object':
        break
      default:
        this.environment = {}
    }

    if (typeof (this.environment) === 'object') {
      environmentModel(this.environment, this.action)
    }
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    return new Action(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__lastStart',
      '__socketId',
      'tags.table',
      'action_id',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name',
      'status',
      'build',
      'fppm',
      'starting'
    ])
    return obj
  }

  async refresh () {
    const action = await this.request({
      method: 'GET',
      url: `v3/actions/${this._id}`,
      params: {
        repository: this.repository,
        repository_version: this.repository_version
      }
    })
    this.assign(action)
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v3/actions'
    })
  }

  async save () {
    // Tracking
    const environment = typeof (this.environment) === 'string' ? Store.getters.DPE_ENVIRONMENT_BY_ID(this.environment) || {} : this.environment
    Vue.$analytics.track('Edit action', {
      action_id: this.action_id,
      execution_mode: environment.type === 'deployment' ? 'always-ready' : 'serverless',
      action_type: this.action,
      environment_selected: typeof (this.environment) === 'string',
      perimeter_option: environment.params?.perimeter?.type ? Vue.i18n.translate(`preferences.editor.type.${environment.params?.perimeter?.type}`) : 'None',
      segmentation_option: environment.params?.segmentation?.type ? Vue.i18n.translate(`preferences.editor.type.${environment.params?.segmentation?.type}`) : 'None',
      resources_type: environment.fpu?.type,
      resources_allocated: environment.fpu?.size * environment.fpu?.instances,
      nb_of_instances: environment.fpu?.instances
    })

    return super.save({
      method: 'PUT',
      url: `v3/actions/${this.action_id}`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async remove () {
    Vue.$analytics.track('Delete action', {
      action_id: this.action_id
    })

    return this.request({
      method: 'DELETE',
      url: `v3/actions/${this.action_id}`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async install () {
    return this.request({
      method: 'PUT',
      url: `v3/actions/${this.action_id}/install`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async start () {
    try {
      this.starting = true
      Store.commit('SET_OPEN_CONSOLE', true)
      await this.request({
        method: 'POST',
        url: `v3/actions/${this.action_id}/start`,
        params: {
          repository_version: this.repository_version
        }
      })

      // this.__lastStart = response.headers.date
    } catch (err) {
      // We handle 409, cause it should push the request into "started state"
      if (err.status !== 409) {
        throw err
      }
      // this.__lastStart = err.date
    } finally {
      this.starting = false
    }
  }

  async stop () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v3/actions/${this.action_id}/stop`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async stopBuild () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v3/actions/${this.action_id}/build/stop`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  async stopInstall () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v3/actions/${this.action_id}/install`,
      params: {
        repository_version: this.repository_version
      }
    })
  }

  get buildTime () {
    if (!this.buildStartTime) return null
    if (this.buildEndTime) {
      return this.buildEndTime.unix() - this.buildStartTime.unix()
    }
    return moment().unix() - this.buildStartTime.unix()
  }

  get buildStartTime () {
    if (!_get(this, 'build.start_time')) return null
    return Date(this.build.start_time)
  }

  get buildEndTime () {
    if (!_get(this, 'build.end_time')) return null
    return Date(this.build.end_time)
  }
}

class Actions extends API {
  list (options = {}) {
    const output = (actions) => {
      return actions.map(action => {
        try {
          return new Action(action)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    }

    // const data = mockActions // Need to replace 'repository' and 'repository_version' in the file
    // return output(data)

    return this.paginateCursor({
      method: 'get',
      url: 'v3/actions',
      onProgress (actions) {
        if (options?.onProgress) options.onProgress(output(actions))
      }
    }).then(actions => {
      return output(actions)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Action(item)
  }

  async versions () {
    return await this.request({
      method: 'get',
      url: 'v3/actions/versions'
    })
    // return mockActionsVersions // Mock
  }
}

export default Actions
export {
  Action,
  Actions
}
